<template>
  <div>
    <!-- header -->
    <div class="flex flex-wrap items-center -ml-16 sm:-ml-24 -mt-8">
      <div class="mr-auto ml-16 sm:ml-24 mt-8">
        <p class="text-lg font-medium leading-big">{{ orderData.order.name }}</p>
        <p class="mt-4 text-grey-400">
          Oprettet
          <time datetime="2020-01-07">{{ orderData.order.created_at | formatDate }} - {{ orderData.order.created_at | formatTime }}</time>
        </p>
      </div>

      <el-button
        tag="button"
        type="primary"
        class="ml-16 sm:ml-24 mt-8"
        @click="onPrint"
      >
        Print
      </el-button>
    </div>

    <hr class="my-20 -mx-24 border-none h-1 bg-hr">

    <!-- customer -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Kunde</p>
      <p class="mt-4 sm:mt-0 sm:col-span-2">{{ getFullName(orderData.order.shopifyCustomer) }}</p>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- status -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Status</p>
      <div class="flex mt-4 sm:mt-0 sm:col-span-2">
        <el-status :status="orderData.order.fulfillmentStatus" />
      </div>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- address -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Adresse</p>
      <p class="mt-4 sm:mt-0 sm:col-span-2">{{ orderData.order.shipping_address.first_name }} {{ orderData.order.shipping_address.last_name }}<br>{{ getFullAddress(orderData.order.shipping_address) }}</p>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- email -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Email adresse</p>
      <p class="mt-4 sm:mt-0 sm:col-span-2">{{ orderData.order.shopifyCustomer.email }}</p>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- phone -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Telefon</p>
      <p class="mt-4 sm:mt-0 sm:col-span-2">{{ orderData.order.shipping_address.phone ? orderData.order.shipping_address.phone : '-' }}</p>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- shipping -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Forsendelse</p>
      <p class="mt-4 sm:mt-0 sm:col-span-2">{{ orderData.line_items[0].shipping }}</p>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- track -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Track'n'Trace</p>
      <div class="mt-4 sm:mt-0 sm:col-span-2">
        <p>{{ orderData.line_items[0].track_and_trace }}</p>
        <p class="text-grey-300">Tilføjelse af Track'n'Trace giver mulighed for notificering af kunden ved afsendelse</p>
      </div>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- order total -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Ordre total</p>
      <p class="text-lg mt-4 sm:mt-0 sm:col-span-2">
        kr. {{ getOrderTotal | formaNumber(2) }} <span class="text-grey-300 text-sm">(Heraf moms kr. {{ getOrderTaxTotal | formaNumber(2) }})</span>
      </p>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- order items -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Ordrelinier</p>
      <order-items :order-items="orderData.line_items" :is-status-fulfilled="isStatusFulfilled" class="mt-4 sm:mt-0 sm:col-span-2" />
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <!-- notat -->
    <div class="mb-32 sm:mb-0 sm:grid sm:grid-cols-3 sm:gap-16">
      <p class="font-medium text-grey-400 max-w-sm w-full">Notat</p>
      <p class="mt-4 sm:mt-0 sm:col-span-2">{{ orderData.order.note }}</p>
    </div>

    <hr class="hidden sm:block my-20 -mx-24 border-none h-1 bg-hr">

    <div class="flex items-center justify-end">
      <el-button
        type="primary"
        :loading="isLoading"
        :disabled="orderData.order.fulfillmentStatus === 'Fulfilled' || orderData.order.fulfillmentStatus === 'Refunded'"
        class="ml-12 min-w-160"
        @click="$emit('fulfill-order')"
      >
        Afsend ordrelinier
      </el-button>
    </div>
  </div>
</template>

<script>
const ElButton = () => import('@/components/shared/ELButton')
const ElStatus = () => import('@/components/shared/ELStatus')
const OrderItems = () => import('@/components/order/OrderItems')

export default {
  name: 'OrderTable',

  components: { ElButton, ElStatus, OrderItems },

  props: {
    orderData: {
      type: Object,
      required: true
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {}
  },

  computed: {
    getOrderTaxTotal () {
      let arrayOfTaxItems = []
      this.orderData.line_items.map(line => {
        arrayOfTaxItems = [...arrayOfTaxItems, ...line.line_item.tax_lines]
      })
      return arrayOfTaxItems.map(taxItem => +taxItem.price).reduce((initial, next) => +initial + +next, 0)
    },

    getOrderTotal () {
      const multipledValues = this.orderData.line_items.map(line => {
        let lineDiscount = line.line_item.total_discount
        if (line.line_item.discount_allocations && line.line_item.discount_allocations.length) {
          lineDiscount = line.line_item.discount_allocations.reduce((initial, next) => initial + parseFloat(next.amount), 0)
        }
        return (line.line_item.price * line.line_item.quantity) - lineDiscount
      })
      return multipledValues.reduce((initial, next) => initial + next, 0)
    },

    isStatusFulfilled () {
      return this.orderData.order.fulfillmentStatus === 'Fulfilled' || this.orderData.order.fulfillmentStatus === 'Refunded'
    }
  },

  methods: {
    onPrint () {
      window.print()
    },

    getFullName (data) {
      return data ? `${data.first_name} ${data.last_name}` : ''
    },

    getFullAddress (data) {
      return `${data.address1}, ${data.address2}${data.address2 ? ',' : ''} ${data.zip} ${data.city}`
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
